import { UserDataRequest } from '@amedia/user';
import './aid-arrow.js';
import './aid-username.js';
import './aid-menu.js';

function stringToHTML(html) {
  return new DOMParser().parseFromString(html, 'text/html').body.firstChild;
}

class AmediaUser extends HTMLElement {
  connectedCallback() {
    if (!this.initialized) {
      this.init();
    }
  }

  init() {
    this.disable = window.location.href.indexOf('disable_aid_menu=true') > -1;
    this.initialized = true;
    this.publication = this.getAttribute('publication');
    this.locale = this.getAttribute('locale') || '';
    this.mypage = this.getAttribute('mypage') || '';
    this.links = this.getAttribute('links');
    this.orderpage = this.getAttribute('orderpage') || '';
    this.subscriptionLink = this.getAttribute('subscription-link') || '';
    this.subscriptionText = this.getAttribute('subscription-text') || '';
    this.theme = this.getAttribute('theme');
    this.mockUser = this.getAttribute('mockuser');

    if (this.mockUser) {
      this.render();
      return;
    }

    new UserDataRequest()
      .withContext('ego')
      .withAttributes(['name', 'access'])
      .subscribe(this.onUserDataUpdated.bind(this));
  }

  onUserDataUpdated({ attributes, state }) {
    if (state.isLoggedIn !== true && state.isLoggedIn !== false) {
      if (state.emergencyMode && state.emergencyMode.includes('aid')) {
        this.renderEmergencyMode(
          state.emergencyMode.includes('firedrill-in-progress')
        );
      }
    } else {
      this.isLoggedIn = state.isLoggedIn;
      this.user = {
        name: attributes.name || 'Innlogget',
        isEmployee: !!attributes.access?.includes('amedia_staff'),
      };
      this.renderUserMenu();
    }
  }

  renderUserMenu() {
    this.querySelector('.emergency-mode')?.remove();
    if (this.disable) {
      this.setAttribute('style', 'display:none');
      return null;
    }

    let attr;
    if (this.isLoggedIn) {
      attr = `authenticated username="${this.user.name}" isEmployee="${this.user.isEmployee}"`;
    } else if (this.mockUser) {
      attr = `authenticated username="${this.mockUser}" isMockUser="true"`;
    } else {
      attr = `orderpage="${this.orderpage}" publication="${this.publication}" subscription-text="${this.subscriptionText}" subscription-link="${this.subscriptionLink}" links="${this.links}"`;
    }
    const usernameHTML = `<aid-username ${attr} theme="${this.theme}"></aid-username>`;
    const aidMenuHTML = `<aid-menu
                class="${this.theme}"
                locale="${this.locale}"
                publication="${this.publication}"
                mypage="${this.mypage}"
                links="${this.links}"
                isLoggedIn="${this.isLoggedIn}"
            </aid-menu>`;

    const usernameEl = this.querySelector('aid-username');
    if (usernameEl) {
      usernameEl.replaceWith(stringToHTML(usernameHTML));
    } else {
      this.insertAdjacentHTML('afterbegin', usernameHTML);
    }

    const menuEl = this.querySelector('aid-menu');
    if (menuEl) {
      menuEl.replaceWith(stringToHTML(aidMenuHTML));
    } else {
      this.insertAdjacentHTML('afterbegin', aidMenuHTML);
    }
  }

  renderEmergencyMode(isFireDrill) {
    this.querySelector('aid-username')?.remove();
    this.querySelector('aid-menu')?.remove();
    if (!this.querySelector('.emergency-mode')) {
      this.insertAdjacentHTML(
        'afterbegin',
        `<span class="emergency-mode">${
          isFireDrill
            ? 'Planlagt systemtest, straks tilbake'
            : 'Tekniske problemer'
        }</span>`
      );
    }
  }
}

customElements.define('amedia-user', AmediaUser);
