import { aidUrls, logout } from '@amedia/user';

let transition;
if ('ontransitionend' in window) {
  transition = 'transitionend';
} else {
  transition = false;
}

class AidMenu extends HTMLElement {
  #created = false;

  connectedCallback() {
    if (!this.#created) {
      this.init();
    }
    this.#created = true;
  }

  init() {
    this.locale = this.getAttribute('locale');
    this.mypage = this.getAttribute('mypage');

    const links = this.getAttribute('links');
    this.links = links ? JSON.parse(decodeURIComponent(links)) : [];

    const isLoggedIn = this.getAttribute('isLoggedIn') === 'true';
    this.render(isLoggedIn);

    window.addEventListener('aid-menu-toggle', this.toggleMenuOpen.bind(this));
  }

  /**
   * @param {CustomEvent} evt
   */
  toggleMenuOpen(evt) {
    if (evt.detail) {
      this.setAttribute('style', 'display:block');
      const height = this.scrollHeight;
      if (transition) {
        const end = () => {
          this.style.height = `${height}px`;
          this.removeEventListener(transition, end);
        };
        this.style.height = `${height + 30}px`;
        this.addEventListener(transition, end);
      } else {
        this.style.height = `${height}px`;
      }
    } else {
      this.style.height = '0';
      this.addEventListener(
        transition,
        () => {
          this.setAttribute('style', 'display:none');
        },
        { once: true }
      );
    }
  }

  /**
   * @param {boolean} isLoggedIn
   */
  render(isLoggedIn) {
    this.setAttribute('style', 'display:none');
    const links = this.links.filter((link) => link.authenticated);
    if (this.mypage) {
      links.push({ url: this.mypage, text: 'Mine abonnement' });
    }

    links.push({ url: aidUrls.profile.href, text: 'Min aID' });
    links.push({
      url: aidUrls.privacyPolicy.href,
      text: 'Personvern',
    });

    const linkshtml = links
      .map((link) => `<li><a href="${link.url}">${link.text}</a></li>`)
      .join('');
    const linkList = document.createElement('ul');
    linkList.insertAdjacentHTML('beforeend', linkshtml);

    if (isLoggedIn) {
      const element = document.createElement('a');
      element.setAttribute('href', '/aid/logout'); // TODO Should be replaced with something from @amedia/user.
      element.textContent = 'Logg ut';
      element.classList.add('element', 'lp_logout_globy');
      element.addEventListener('click', (e) => {
        e.preventDefault();
        logout().then(() => {
          window.location.hash = '';
          window.location.reload();
        });
      });
      const li = document.createElement('li');
      li.appendChild(element);
      linkList.appendChild(li);
    }
    this.appendChild(linkList);
  }
}

customElements.define('aid-menu', AidMenu);
